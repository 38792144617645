import { FC } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { FaBell, FaCheck, FaComment, FaEdit, FaFile, FaPlus, FaRedo, FaStamp, FaTrash, FaUndo } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import { useRouteParams } from "app/route/route";
import {
    createReportVersionVulnerabilityActionRoute,
    editReportVersionVulnerabilityActionRoute,
    reportVersionVulnerabilityRoute,
    resolveReportVersionVulnerabilityRoute,
    reopenReportVersionVulnerabilityRoute,
    deleteReportVersionVulnerabilityActionRoute,
    editReportVersionVulnerabilityRoute,
    deleteReportVersionVulnerabilityRoute,
    vulnerabilityRoute,
    acceptRiskReportVersionVulnerabilityRoute,
    verifyResolvedReportVersionVulnerabilityRoute,
    createReportVersionVulnerabilityRetestRequestRoute,
    createReportVersionVulnerabilityClientNotificationRoute,
    createVulnerabilityRoute,
    createReportVersionVulnerabilityFileRoute,
    editReportVersionVulnerabilityFileRoute,
    deleteReportVersionVulnerabilityFileRoute,
} from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { ReportVulnerabilityStatus, useReportVulnerabilityQuery } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { SeverityBadge } from "app/component/report-vulnerability/component/SeverityBadge";
import { formatISODate, formatISODateTime } from "app/util/format";
import { HtmlContent } from "app/component/util/HtmlContent";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useReportVulnerabilityActions } from "app/api/graph/hook/use-report-vulnerability-actions";
import { ReportVulnerabilityStatusBadge } from "app/component/report-vulnerability/component/ReportVulnerabilityStatusBadge";
import { ActionFiles } from "app/component/report-vulnerability-action/component/ActionFiles";
import { useAuth } from "app/api/AuthContext";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import {
    createVulnerabilityActionRole,
    deleteVulnerabilityActionRole,
    deleteReportVulnerabilityRole,
    transitionReportVulnerabilityRole,
    updateVulnerabilityActionRole,
    updateReportVulnerabilityRole,
    testerRole,
    createVulnerabilityClientNotificationRole,
    createVulnerabilityRole,
} from "app/api/graph/roles";
import { ReportVulnerabilityComments } from "app/component/report-vulnerability/ReportVulnerabilityComments";
import { ButtonLink } from "app/component/button/ButtonLink";
import { mapNodes } from "app/util/graph.ts";
import { LocationState } from "app/component/vulnerability/CreateVulnerabilityScreen.tsx";

const ActionsList: FC<{ reportId: number; versionId: number; vulnerabilityId: number }> = ({
    reportId,
    versionId,
    vulnerabilityId,
}) => {
    const { roles } = useAuth();
    const { actions, loading, error } = useReportVulnerabilityActions(vulnerabilityId);

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!actions) {
        return <Alert variant="danger">Not found.</Alert>;
    }
    if (actions.length === 0) {
        return <Alert variant="warning">No actions have been recorded.</Alert>;
    }

    return (
        <>
            {actions.map((action) => (
                <Card key={action.id} className="mb-3">
                    <Card.Header>
                        <Toolbar>
                            <ToolbarTitle>
                                <strong>{formatISODate(action.dateActioned)}</strong>
                                {action.clientReference ? ` (${action.clientReference})` : null}
                                {action.createdByFullName ? ` - ${action.createdByFullName}` : null}
                            </ToolbarTitle>
                            <ToolbarButtons>
                                <ButtonGroup>
                                    {roles?.includes(updateVulnerabilityActionRole) ? (
                                        <ButtonLink
                                            variant="warning"
                                            to={editReportVersionVulnerabilityActionRoute.build({
                                                reportId,
                                                versionId,
                                                vulnerabilityId,
                                                actionId: action._id,
                                            })}
                                        >
                                            <FaEdit /> Edit
                                        </ButtonLink>
                                    ) : null}
                                    {roles?.includes(deleteVulnerabilityActionRole) ? (
                                        <ButtonLink
                                            variant="danger"
                                            to={deleteReportVersionVulnerabilityActionRoute.build({
                                                reportId,
                                                versionId,
                                                vulnerabilityId,
                                                actionId: action._id,
                                            })}
                                        >
                                            <FaTrash /> Delete
                                        </ButtonLink>
                                    ) : null}
                                </ButtonGroup>
                            </ToolbarButtons>
                        </Toolbar>
                    </Card.Header>
                    <Card.Body>
                        <HtmlContent html={action.description} />
                        {(action.files?.edges?.length ?? 0 > 0) ? (
                            <ActionFiles action={action} versionId={versionId} />
                        ) : null}
                    </Card.Body>
                </Card>
            ))}
        </>
    );
};

export const ReportVersionVulnerabilityScreen: FC = () => {
    const { roles } = useAuth();
    const { reportId, versionId, vulnerabilityId } = useRouteParams(reportVersionVulnerabilityRoute);
    const { data, loading, error } = useReportVulnerabilityQuery({
        variables: { vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId) },
    });
    const vulnerability = data?.reportVulnerability;
    const testingTypes = mapNodes(vulnerability?.testingTypes, (types) => types);

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!vulnerability) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionVulnerabilityRoute} />
                <Row>
                    <Col xs="auto" className="ms-auto">
                        <ButtonGroup className="me-2">
                            {roles?.includes(transitionReportVulnerabilityRole) ? (
                                <>
                                    {vulnerability.status === ReportVulnerabilityStatus.Open ? (
                                        <Dropdown as={ButtonGroup}>
                                            <ButtonLink
                                                to={resolveReportVersionVulnerabilityRoute.build({
                                                    reportId,
                                                    versionId,
                                                    vulnerabilityId,
                                                })}
                                                variant="success"
                                            >
                                                <FaCheck /> Resolve
                                            </ButtonLink>

                                            <Dropdown.Toggle split variant="success" />

                                            {!roles?.includes(testerRole) ? (
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        as={Link}
                                                        to={acceptRiskReportVersionVulnerabilityRoute.build({
                                                            reportId,
                                                            versionId,
                                                            vulnerabilityId,
                                                        })}
                                                    >
                                                        Accept risk
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            ) : null}
                                        </Dropdown>
                                    ) : null}
                                    {vulnerability.status === ReportVulnerabilityStatus.Resolved &&
                                    roles?.includes(testerRole) ? (
                                        <ButtonLink
                                            to={verifyResolvedReportVersionVulnerabilityRoute.build({
                                                reportId,
                                                versionId,
                                                vulnerabilityId,
                                            })}
                                            variant="success"
                                        >
                                            <FaStamp /> Verify resolved
                                        </ButtonLink>
                                    ) : null}
                                    {[
                                        ReportVulnerabilityStatus.Resolved,
                                        ReportVulnerabilityStatus.RiskAccepted,
                                        ReportVulnerabilityStatus.VerifiedResolved,
                                    ].includes(vulnerability.status) ? (
                                        <ButtonLink
                                            to={reopenReportVersionVulnerabilityRoute.build({
                                                reportId,
                                                versionId,
                                                vulnerabilityId,
                                            })}
                                            variant="danger"
                                        >
                                            <FaUndo /> Reopen
                                        </ButtonLink>
                                    ) : null}
                                </>
                            ) : null}
                            {roles?.includes(createVulnerabilityClientNotificationRole) ? (
                                <ButtonLink
                                    to={createReportVersionVulnerabilityClientNotificationRoute.build({
                                        reportId,
                                        versionId,
                                        vulnerabilityId,
                                    })}
                                    variant="primary"
                                >
                                    <FaBell /> Raise client notification
                                </ButtonLink>
                            ) : null}
                            <ButtonLink
                                to={createReportVersionVulnerabilityRetestRequestRoute.build({
                                    reportId,
                                    versionId,
                                    vulnerabilityId,
                                })}
                                variant="primary"
                            >
                                <FaRedo /> Request re-test
                            </ButtonLink>
                        </ButtonGroup>
                        <ButtonGroup>
                            {roles?.includes(updateReportVulnerabilityRole) ? (
                                <ButtonLink
                                    to={editReportVersionVulnerabilityRoute.build({
                                        reportId,
                                        versionId,
                                        vulnerabilityId,
                                    })}
                                    variant="warning"
                                >
                                    <FaEdit /> Edit
                                </ButtonLink>
                            ) : null}
                            {roles?.includes(deleteReportVulnerabilityRole) ? (
                                <ButtonLink
                                    to={deleteReportVersionVulnerabilityRoute.build({
                                        reportId,
                                        versionId,
                                        vulnerabilityId,
                                    })}
                                    variant="danger"
                                >
                                    <FaTrash /> Delete
                                </ButtonLink>
                            ) : null}
                            {roles?.includes(createVulnerabilityRole) ? (
                                <ButtonLink
                                    to={createVulnerabilityRoute.build({})}
                                    variant="success"
                                    state={
                                        {
                                            initialValues: {
                                                title: vulnerability.title,
                                                severity: vulnerability.severity,
                                                testingTypes: mapNodes(vulnerability.testingTypes, (item) => item.id),
                                                cvssScore: vulnerability.cvssScore,
                                                cvssVector: vulnerability.cvssVector,
                                                cveScore: vulnerability.cveScore,
                                                cwe: vulnerability.cwe,
                                                owaspCategory: vulnerability.owaspCategory,
                                                issueDetail: vulnerability.issueDetail,
                                                advice: vulnerability.advice,
                                            },
                                        } as LocationState
                                    }
                                >
                                    <FaPlus /> Add to library
                                </ButtonLink>
                            ) : null}
                        </ButtonGroup>
                    </Col>
                </Row>
                <h1>
                    <SeverityBadge severity={vulnerability.severity} className="me-2" />
                    <strong className="me-1">{vulnerability.issueNumber}</strong> {vulnerability.title}
                </h1>

                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>Status</th>
                            <td>
                                <ReportVulnerabilityStatusBadge status={vulnerability.status} />
                            </td>
                        </tr>
                        <tr>
                            <th>Discovered on</th>
                            <td>{formatISODate(vulnerability.dateDiscovered)}</td>
                        </tr>
                        <tr>
                            <th>Resolved on</th>
                            <td>{formatISODate(vulnerability.dateResolved)}</td>
                        </tr>
                        {vulnerability.reopenedAt ? (
                            <>
                                <tr>
                                    <th>Reopened at</th>
                                    <td>{formatISODateTime(vulnerability.reopenedAt)}</td>
                                </tr>
                                <tr>
                                    <th>Reopened by</th>
                                    <td>{vulnerability.reopenedByFullName ?? "-"}</td>
                                </tr>
                                <tr>
                                    <th>Reopened reason</th>
                                    <td>{vulnerability.reopenedReason ?? "-"}</td>
                                </tr>
                            </>
                        ) : null}
                        <tr>
                            <th>Testing types</th>
                            <td>
                                {testingTypes.length > 0
                                    ? testingTypes.map((testingType) => (
                                          <Badge key={testingType.id}>{testingType.name}</Badge>
                                      ))
                                    : "None assigned"}
                            </td>
                        </tr>
                        <tr>
                            <th>CVSS score</th>
                            <td>{vulnerability.cvssScore ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>CVSS vector</th>
                            <td>{vulnerability.cvssVector ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>CVE score</th>
                            <td>{vulnerability.cveScore ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>CWE</th>
                            <td>{vulnerability.cwe ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>OWASP category</th>
                            <td>{vulnerability.owaspCategory ?? "-"}</td>
                        </tr>
                        {vulnerability.baseVulnerability ? (
                            <tr>
                                <th>Master vulnerability</th>
                                <td>
                                    <Link
                                        to={vulnerabilityRoute.build({
                                            vulnerabilityId: vulnerability.baseVulnerability._id,
                                        })}
                                    >
                                        ({vulnerability.baseVulnerability.identifier}){" "}
                                        {vulnerability.baseVulnerability.title}
                                    </Link>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>

                <Button variant="info" href="#comments" className="mb-3">
                    <FaComment /> Discuss this vulnerability with the tester below
                </Button>

                <h2>Affected hosts</h2>
                <HtmlContent html={vulnerability.affectedHosts ?? "-"} />

                <h2>Issue detail</h2>
                <HtmlContent html={vulnerability.issueDetail ?? "-"} />

                <h2>Remediation advice and references</h2>
                <HtmlContent html={vulnerability.advice ?? "-"} />

                <Toolbar>
                    <ToolbarTitle>
                        <h2>Files</h2>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(createVulnerabilityActionRole) ? (
                            <ButtonLink
                                to={createReportVersionVulnerabilityFileRoute.build({
                                    reportId,
                                    versionId,
                                    vulnerabilityId,
                                })}
                                variant="success"
                            >
                                <FaPlus /> New file
                            </ButtonLink>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>

                {mapNodes(vulnerability.files, (file) => (
                    <Card key={file.id} className="mb-3">
                        <Card.Header>
                            <Toolbar>
                                <ToolbarTitle>
                                    #{file.position}.&nbsp;
                                    {file.reportMediaObject.originalName}
                                </ToolbarTitle>
                                <ToolbarButtons>
                                    {roles?.includes(updateReportVulnerabilityRole) ? (
                                        <ButtonLink
                                            variant="warning"
                                            to={editReportVersionVulnerabilityFileRoute.build({
                                                reportId,
                                                versionId,
                                                vulnerabilityId,
                                                fileId: file._id,
                                            })}
                                        >
                                            <FaEdit /> Edit
                                        </ButtonLink>
                                    ) : null}
                                    {roles?.includes(deleteReportVulnerabilityRole) ? (
                                        <ButtonLink
                                            variant="danger"
                                            to={deleteReportVersionVulnerabilityFileRoute.build({
                                                reportId,
                                                versionId,
                                                vulnerabilityId,
                                                fileId: file._id,
                                            })}
                                        >
                                            <FaTrash /> Delete
                                        </ButtonLink>
                                    ) : null}
                                </ToolbarButtons>
                            </Toolbar>
                        </Card.Header>
                        <Card.Body>
                            <figure className="figure">
                                <a
                                    href={file.reportMediaObject.contentUrl ?? ""}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {file.reportMediaObject.mimeType?.startsWith("image/") ? (
                                        <img
                                            className="figure-img img-fluid"
                                            src={file.reportMediaObject.contentUrl ?? ""}
                                            alt={file.reportMediaObject.originalName ?? "Screenshot"}
                                        />
                                    ) : (
                                        <>
                                            <FaFile />
                                            {file.reportMediaObject.originalName}
                                        </>
                                    )}
                                </a>

                                {file.caption != null && (
                                    <figcaption className="figure-caption">{file.caption}</figcaption>
                                )}
                            </figure>

                            {file.description != null && <HtmlContent html={file.description} />}
                        </Card.Body>
                    </Card>
                ))}

                <Toolbar>
                    <ToolbarTitle>
                        <h2>Actions taken</h2>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(createVulnerabilityActionRole) ? (
                            <ButtonLink
                                to={createReportVersionVulnerabilityActionRoute.build({
                                    reportId,
                                    versionId,
                                    vulnerabilityId,
                                })}
                                variant="success"
                            >
                                <FaPlus /> New action
                            </ButtonLink>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <ActionsList reportId={reportId} versionId={versionId} vulnerabilityId={vulnerabilityId} />

                <h2 id="comments">Remediation notifications</h2>
                <ReportVulnerabilityComments vulnerabilityId={vulnerabilityId} />
            </Container>
        </NavigationScreen>
    );
};
