import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { formatISO } from "date-fns";
import { ReportVersionListFieldsFragment, useCompanyReportVersionsListQuery } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { useFetchAll } from "app/api/graph/hook/use-fetch-all";

type Result = {
    versions?: ReportVersionListFieldsFragment[];
    loading: boolean;
    error?: ApolloError;
};

type Filters = {
    publishedAtFrom?: Date;
    publishedAtTo?: Date;
};

export const useCompanyReportVersionsList = (companyId: number, filters?: Filters): Result => {
    const { data, loading, error, fetchMore } = useCompanyReportVersionsListQuery({
        variables: {
            companyId: companyGraphId(companyId),
            ...(filters && {
                publishedAt: {
                    after: filters.publishedAtFrom ? formatISO(filters.publishedAtFrom) : undefined,
                    before: filters.publishedAtTo ? formatISO(filters.publishedAtTo) : undefined,
                },
            }),
        },
        notifyOnNetworkStatusChange: true,
    });
    const versions = data?.companyReportVersions;
    const pageInfo = versions?.pageInfo;
    useFetchAll({ loading, pageInfo, fetchMore });

    return useMemo(
        () => ({
            versions: mapNodes(versions, (n) => n),
            loading,
            error,
        }),
        [versions, loading, error],
    );
};
