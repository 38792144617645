import { FC } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { useRouteParams } from "app/route/route";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { reportVersionReadReceiptsRoute } from "app/route/Routes";
import { useReportVersionReadReceiptsListQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { mapNodes } from "app/util/graph";
import { formatISODateTime } from "app/util/format";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

export const ReportVersionReadReceiptsScreen: FC = () => {
    const { versionId } = useRouteParams(reportVersionReadReceiptsRoute);
    const { data, loading, error, fetchMore } = useReportVersionReadReceiptsListQuery({
        notifyOnNetworkStatusChange: true,
        variables: { versionId: reportVersionGraphId(versionId) },
    });
    const receipts = data?.reportVersion?.readReceipts;

    if (loading && !receipts) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!receipts) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    const fetchMoreReceipts = () => fetchMore({ variables: { after: receipts.pageInfo.endCursor } });

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionReadReceiptsRoute} />
                <Table>
                    <thead>
                        <tr>
                            <th>Who</th>
                            <th>When</th>
                            <th>Section</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mapNodes(receipts, (receipt) => (
                            <tr key={receipt.id}>
                                <td>{receipt.createdByFullName ?? "-"}</td>
                                <td>{formatISODateTime(receipt.createdAt)}</td>
                                <td>{receipt.sectionNumber}</td>
                            </tr>
                        ))}
                        {receipts.edges?.length === 0 ? (
                            <tr>
                                <td colSpan={100}>No receipts.</td>
                            </tr>
                        ) : null}
                        {loading ? (
                            <tr>
                                <td colSpan={100}>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                        ) : null}
                        {receipts.pageInfo.hasNextPage ? (
                            <tr>
                                <td colSpan={100}>
                                    <BlockButtonGroup>
                                        <Button variant="light" onClick={fetchMoreReceipts}>
                                            Load more
                                        </Button>
                                    </BlockButtonGroup>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>
            </Container>
        </NavigationScreen>
    );
};
