import { FC, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useReportsListQuery } from "app/api/graph/types";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { deleteReportRoute, editReportRoute, reportRoute } from "app/route/Routes";
import { SortableTableHeader, SortDirection } from "app/component/table/SortableTableHeader";
import { deleteReportRole, updateReportRole } from "app/api/graph/roles";
import { formatISODateTime } from "app/util/format";
import { useAuth } from "app/api/AuthContext";
import { ReportsTableFiltersForm, FormValues } from "app/component/report/ReportsTableFiltersForm";
import { DownloadReportVersionTestCertificatePdfButton } from "app/component/report-version/component/DownloadReportVersionTestCertificatePdfButton";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

export const ReportsTable: FC = () => {
    const { roles } = useAuth();
    const [titleOrder, setTitleOrder] = useState<SortDirection>(undefined);
    const [createdAtOrder, setCreatedAtOrder] = useState<SortDirection>("DESC");
    const [filters, setFilters] = useState<FormValues>({ title: undefined });
    const { data, loading, error, fetchMore } = useReportsListQuery({
        variables: { order: { title: titleOrder, createdAt: createdAtOrder }, ...filters },
        notifyOnNetworkStatusChange: true,
    });
    const reports = data?.reports;

    if (loading && !reports) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!reports) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreReports = () => fetchMore({ variables: { after: reports.pageInfo.endCursor } });

    return (
        <Row>
            <Col>
                <Table responsive="sm" bordered className="crud-table">
                    <thead>
                        <tr>
                            <SortableTableHeader direction={titleOrder} onChange={setTitleOrder}>
                                Title
                            </SortableTableHeader>
                            <th>Company</th>
                            <SortableTableHeader direction={createdAtOrder} onChange={setCreatedAtOrder}>
                                Created at
                            </SortableTableHeader>
                            <th>Created by</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mapNodes(reports, (report) => (
                            <tr key={report.id}>
                                <td>
                                    <Link to={reportRoute.build({ reportId: report._id })}>{report.title}</Link>
                                </td>
                                <td>{report.company?.name ?? "-"}</td>
                                <td>{formatISODateTime(report.createdAt)}</td>
                                <td>{report.createdByFullName ?? "-"}</td>
                                <td>
                                    {report.latestVersion?._id != null ? (
                                        <ButtonGroup>
                                            <DownloadReportVersionTestCertificatePdfButton
                                                title="Download test certificate PDF"
                                                titleText=""
                                                size="sm"
                                                versionId={report.latestVersion._id}
                                            />
                                        </ButtonGroup>
                                    ) : null}
                                    {roles?.includes(updateReportRole) ? (
                                        <ButtonGroup>
                                            <ButtonLink
                                                to={editReportRoute.build({ reportId: report._id })}
                                                variant="warning"
                                                size="sm"
                                                title="Edit"
                                            >
                                                <FaEdit />
                                            </ButtonLink>
                                        </ButtonGroup>
                                    ) : null}
                                    {roles?.includes(deleteReportRole) ? (
                                        <ButtonGroup>
                                            <ButtonLink
                                                to={deleteReportRoute.build({ reportId: report._id })}
                                                variant="danger"
                                                size="sm"
                                                title="Delete"
                                            >
                                                <FaTrash />
                                            </ButtonLink>
                                        </ButtonGroup>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                        {reports.edges?.length === 0 ? (
                            <tr>
                                <td colSpan={100}>No reports.</td>
                            </tr>
                        ) : null}
                        {loading ? (
                            <tr>
                                <td colSpan={100}>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                        ) : null}
                        {reports.pageInfo.hasNextPage ? (
                            <tr>
                                <td colSpan={100}>
                                    <BlockButtonGroup>
                                        <Button variant="light" onClick={fetchMoreReports}>
                                            Load more
                                        </Button>
                                    </BlockButtonGroup>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>
            </Col>
            <Col md={3}>
                <ReportsTableFiltersForm initialValues={filters} onSubmit={setFilters} />
            </Col>
        </Row>
    );
};
