import { parse as parseCookie } from "cookie";
import { createContext, createRef, FC, PropsWithChildren, useContext, useImperativeHandle, useReducer } from "react";
import { fromUnixTime } from "date-fns";
import { jwtDecode } from "jwt-decode";
import { noop, noopPromise } from "app/util/noop";
import { client } from "app/api/ApiProvider";

const JWT_COOKIE = "jwt_hp";

export const addMobileNumberAction = "ADD_MOBILE_NUMBER";

type AuthResponse = {
    actions?: string[];
};

export type AuthError = {
    code: number;
    message?: string;
};

export type MfaMethod = {
    id: string;
    label: string;
};

export type MfaRequiredAuthError = AuthError & {
    errorCode: "MFA_REQUIRED";
    supportedMethods: MfaMethod[];
};

export type MfaChallengeRequiredAuthError = AuthError & {
    errorCode: "MFA_CHALLENGE_REQUIRED";
};

export const isAuthError = (error: unknown): error is AuthError => {
    return (error as AuthError).code !== undefined;
};

export const isMfaRequiredAuthError = (error: unknown): error is MfaRequiredAuthError => {
    return isAuthError(error) && (error as MfaRequiredAuthError).errorCode === "MFA_REQUIRED";
};

export const isMfaChallengeRequiredAuthError = (error: unknown): error is MfaChallengeRequiredAuthError => {
    return isAuthError(error) && (error as MfaChallengeRequiredAuthError).errorCode === "MFA_CHALLENGE_REQUIRED";
};

type Jwt = {
    exp: number;
    iat: number;
    id: number;
    username: string;
    roles: string[];
};

type State = {
    accessToken?: string;
    expiresAt?: Date;
    id?: number;
    username?: string;
    roles?: string[];
    isSignedIn: boolean;
    isSigningIn: boolean;
    actions?: string[];
};

type Action =
    | { type: "authing" }
    | { type: "authed"; response?: AuthResponse }
    | { type: "signedOut" }
    | { type: "clearAction"; action: string };

type Value = State & {
    signIn: (
        email: string,
        password: string,
        mfaMethod?: string,
        mfaChallenge?: string,
        mfaRemember?: boolean,
    ) => Promise<void>;
    signOut: () => void;
    clearAction: (action: string) => void;
};

export const jwtCookie = (): string | undefined => {
    const cookies = parseCookie(document.cookie);
    return JWT_COOKIE in cookies ? cookies[JWT_COOKIE] : undefined;
};

const jwtToState = (jwt?: string) => {
    if (jwt === undefined) {
        return {};
    }
    const data: Jwt | undefined = jwtDecode(jwt);
    return {
        accessToken: jwt,
        expiresAt: data?.exp ? fromUnixTime(data.exp) : undefined,
        id: data?.id,
        username: data?.username,
        roles: data?.roles,
        isSignedIn: true,
    };
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "authing": {
            return {
                ...state,
                isSigningIn: true,
            };
        }
        case "authed": {
            return {
                ...state,
                ...jwtToState(jwtCookie()),
                isSignedIn: true,
                isSigningIn: false,
                actions: action.response?.actions,
            };
        }
        case "signedOut": {
            return {
                isSigningIn: false,
                isSignedIn: false,
            };
        }
        case "clearAction": {
            return {
                ...state,
                actions: state.actions?.filter((value) => value !== action.action),
            };
        }
        default: {
            return state;
        }
    }
};

const AuthContext = createContext<Value>({
    isSignedIn: false,
    isSigningIn: false,
    signIn: noopPromise,
    signOut: noop,
    clearAction: noop,
});

export const AuthProvider: FC<PropsWithChildren> = (props) => {
    const [state, dispatch] = useReducer(reducer, {
        isSignedIn: jwtCookie() !== undefined,
        isSigningIn: false,
        ...jwtToState(jwtCookie()),
    });

    const signIn = async (
        email: string,
        password: string,
        mfaMethod?: string,
        mfaChallenge?: string,
        mfaRemember?: boolean,
    ) => {
        dispatch({ type: "authing" });
        const response = await fetch("/auth/password", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password, mfaMethod, mfaChallenge, mfaRemember }),
            credentials: "include",
        });
        if (!response.ok) {
            throw await response.json();
        }
        const data = response.status === 200 ? ((await response.json()) as AuthResponse) : undefined; // Can be 204 with an empty body
        dispatch({ type: "authed", response: data });
    };

    const signOut = async () => {
        // Call the logout route to clear the cookies - don't block logging out if it fails though, otherwise the user could get stuck
        await fetch("/auth/logout", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
        });

        // Clear the Apollo cache, as it has sensitive data and data that could relate to the current user
        client.stop();
        await client.resetStore();

        dispatch({ type: "signedOut" });
    };

    const value: Value = {
        ...state,
        signIn,
        signOut,
        clearAction: (action: string) => dispatch({ type: "clearAction", action }),
    };
    useImperativeHandle(AuthContextRef, () => value, [value]);

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const useAuth: () => Value = () => useContext(AuthContext);

export const AuthContextRef = createRef<Value>();
