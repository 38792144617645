import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useRouteParams } from "app/route/route";
import { createReportVersionVulnerabilityClientNotificationRoute } from "app/route/Routes";
import {
    useCreateReportVulnerabilityClientNotificationMutation,
    useReportVulnerabilityClientNotificationsQuery,
} from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { formatISODateTime } from "app/util/format";

type ClientNotificationsHistoryProps = {
    vulnerabilityId: number;
};

const ClientNotificationsHistory: FC<ClientNotificationsHistoryProps> = ({ vulnerabilityId }) => {
    const { data, loading, error } = useReportVulnerabilityClientNotificationsQuery({
        variables: { vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId) },
    });
    const clientNotifications = data?.reportVulnerability?.clientNotifications;

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }

    if (clientNotifications?.edges?.length === 0) {
        return null;
    }

    return (
        <Alert variant="warning">
            The following notifications have already been raised against this vulnerability:
            <ul>
                {mapNodes(clientNotifications, (notification) => (
                    <li key={notification.id}>
                        {formatISODateTime(notification.createdAt)} - {notification.createdByFullName ?? "Unknown"}
                    </li>
                ))}
            </ul>
        </Alert>
    );
};

export const CreateReportVulnerabilityClientNotificationScreen: FC = () => {
    const { vulnerabilityId } = useRouteParams(createReportVersionVulnerabilityClientNotificationRoute);
    const [create, { data, loading, error }] = useCreateReportVulnerabilityClientNotificationMutation({
        variables: { input: { vulnerability: reportVulnerabilityGraphId(vulnerabilityId) } },
    });

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createReportVersionVulnerabilityClientNotificationRoute} />
                {data?.createReportVulnerabilityClientNotification ? (
                    <Alert variant="success">A client-notification has been raised.</Alert>
                ) : (
                    <>
                        {error ? <Alert variant="danger">{error.message}</Alert> : null}
                        <Alert variant="warning">
                            Are you sure that you want to raise a client notification for this vulnerability? All users
                            invited to the report or company will be notified via email.
                        </Alert>
                        <ClientNotificationsHistory vulnerabilityId={vulnerabilityId} />
                        <Button variant="primary" disabled={loading} onClick={() => create()}>
                            Raise client notification
                        </Button>
                    </>
                )}
            </Container>
        </NavigationScreen>
    );
};
