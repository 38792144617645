import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { styled } from "styled-components";
import { useReportVersion } from "app/api/graph/hook/use-report-version";
import { useAuth } from "app/api/AuthContext";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import {
    CompanyListFieldsFragment,
    ReportListFieldsFragment,
    ReportVersionFieldsFragment,
    ReportVersionStatus,
    ReportVulnerabilityFieldsFragment,
    ReportVulnerabilityStatus,
    VulnerabilitySeverity,
} from "app/api/graph/types";
import { testerRole } from "app/api/graph/roles";
import { formatISODate } from "app/util/format";
import { testTypeLabel, vulnerabilitySeverityLabel } from "app/api/graph/strings";
import { useCss } from "app/hook/use-css";
import testCertificateImage from "app/asset/image/test_certificate.png";

const CompanyLogo = styled.img`
    max-width: 250px;
`;

const TestCertificateImageContainer = styled.div`
    position: relative;
`;

const TestCertificateImageContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const TestCertificateImageTitle = styled.div`
    color: #ad1b20;
    font-size: 32px;
`;

const TestCertificateImageDate = styled.div`
    color: #666;
    margin-top: 15px;
    font-size: 20px;
`;

const TestCertificateImage = styled.img`
    max-width: 100%;
`;

const pageCss = `
    @page {
        size: A4;
        margin: 2cm 1cm;
    }

    @media print {
        html, body {
        width: 210mm;
        height: 297mm;
    }

    body {
        -webkit-print-color-adjust:exact;
    }
`;

type InnerProps = {
    report: ReportListFieldsFragment;
    version: ReportVersionFieldsFragment;
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    company: CompanyListFieldsFragment;
};

const TestCertificateInner: FC<InnerProps> = ({ report, version, vulnerabilities, company }) => {
    useCss(pageCss);

    return (
        <div className="test-certificate">
            {company.logo?.contentUrl != null && <CompanyLogo src={company.logo.contentUrl} />}

            <h3>Application name</h3>
            <p>{version.testName}</p>
            <hr />

            <h3>Test dates</h3>
            <p>
                {formatISODate(version.testPerformedFrom)}-{formatISODate(version.testPerformedTo)}
            </p>
            <hr />

            <h3>Test type</h3>
            <p>{version.testType != null ? testTypeLabel(version.testType) : "-"}</p>
            <hr />

            <h3>Testing scope</h3>
            <p>
                The assessment was conducted in adherence with iSTORM® CREST methodology and in line with the scope
                outlined in the iSTORM® rules of engagement document - {report.title}.
            </p>
            <hr />

            <h3>Remaining vulnerabilities</h3>
            <Table size="sm">
                <tbody>
                    {[
                        VulnerabilitySeverity.Critical,
                        VulnerabilitySeverity.High,
                        VulnerabilitySeverity.Medium,
                        VulnerabilitySeverity.Low,
                    ].map((severity) => (
                        <tr key={severity}>
                            <th>Open {vulnerabilitySeverityLabel(severity)}</th>
                            <td>
                                {vulnerabilities.reduce(
                                    (acc, vulnerability) =>
                                        vulnerability.severity === severity &&
                                        vulnerability.status === ReportVulnerabilityStatus.Open
                                            ? acc + 1
                                            : acc,
                                    0,
                                )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <th>Resolved unverified</th>
                        <td>
                            {vulnerabilities.reduce(
                                (acc, vulnerability) =>
                                    vulnerability.status === ReportVulnerabilityStatus.Resolved ? acc + 1 : acc,
                                0,
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th>Risk accepted</th>
                        <td>
                            {vulnerabilities.reduce(
                                (acc, vulnerability) =>
                                    vulnerability.status === ReportVulnerabilityStatus.RiskAccepted ? acc + 1 : acc,
                                0,
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <hr />

            <h3>Certification</h3>
            <TestCertificateImageContainer>
                <TestCertificateImage src={testCertificateImage} />
                <TestCertificateImageContent>
                    <TestCertificateImageTitle>{company.name}</TestCertificateImageTitle>
                    <TestCertificateImageDate>{formatISODate(version.testPerformedTo)}</TestCertificateImageDate>
                </TestCertificateImageContent>
            </TestCertificateImageContainer>

            <h3>Definitions</h3>
            <ul>
                <li>
                    Open vulnerability - a vulnerability identified within the scope of the test that has yet to be
                    resolved.
                </li>
                <li>
                    Resolved unverified - a vulnerability that has been marked as resolved by the client but has not
                    been retested by iSTORM® to confirm successful remediation.
                </li>
                <li>
                    Risk accepted - a vulnerability that has been identified but has been deemed to be an acceptable
                    risk by the client.
                </li>
            </ul>
        </div>
    );
};

type Props = {
    reportId: number;
    versionId: number;
};

export const TestCertificate: FC<Props> = ({ reportId, versionId }) => {
    const { report, version, vulnerabilities, versions, company, loading, error } = useReportVersion(
        reportId,
        versionId,
    );
    const { roles } = useAuth();

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!report || !version || !vulnerabilities || !versions || !company) {
        return <Alert variant="danger">Not found.</Alert>;
    }
    if (version.status === ReportVersionStatus.Draft && !roles?.includes(testerRole)) {
        return (
            <Alert variant="danger">
                This report version hasn&lsquo;t been published yet, please check again later.
            </Alert>
        );
    }

    return (
        <TestCertificateInner report={report} version={version} vulnerabilities={vulnerabilities} company={company} />
    );
};
