import { FC, useState } from "react";
import { Location, Navigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, FormikHelpers } from "formik";
import yup from "app/util/yup";
import logo from "app/asset/image/istorm_logo.png";
import {
    isAuthError,
    isMfaChallengeRequiredAuthError,
    isMfaRequiredAuthError,
    MfaMethod,
    useAuth,
} from "app/api/AuthContext";
import { isDevelopment } from "app/util/environment";
import { dashboardRoute, registerRoute, resetPasswordRequestRoute } from "app/route/Routes";
import { CentredScreen } from "app/component/screen/CentredScreen";
import { MfaChallengeInput } from "app/component/sign-in/component/MfaChallengeInput";
import { StatusAlert } from "app/component/form/StatusAlert";
import { Status } from "app/api/graph/form";
import { FormControlPassword } from "app/component/form/control/FormControlPassword";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

type FormValues = {
    email: string;
    password: string;
    mfaMethod?: string;
    mfaChallenge?: string;
    mfaRemember: boolean;
};

const initialValues: FormValues = {
    email: isDevelopment ? "admin@codevate.com" : "",
    password: isDevelopment ? "admin" : "",
    mfaMethod: undefined,
    mfaChallenge: undefined,
    mfaRemember: true,
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
    mfaMethod: yup.string(),
    mfaChallenge: yup.string().when("mfaMethod", {
        is: (mfaMethod: string | undefined) => typeof mfaMethod === "string" && mfaMethod?.length > 0,
        then: (schema) => schema.required(),
    }),
    mfaRemember: yup.boolean().required(),
});

type LocationState = {
    from: string | Location;
};

const isLocationState = (value: unknown): value is LocationState =>
    typeof value === "object" && value !== null && "from" in value;

export const SignInScreen: FC = () => {
    const { signIn, isSignedIn } = useAuth();
    const location = useLocation();
    const [mfaMethods, setMfaMethods] = useState<MfaMethod[] | undefined>();
    const [mfaChallenged, setMfaChallenged] = useState(false);

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        actions.setStatus(undefined);
        return signIn(values.email, values.password, values.mfaMethod, values.mfaChallenge, values.mfaRemember)
            .catch((e) => {
                if (isMfaRequiredAuthError(e)) {
                    setMfaMethods(e.supportedMethods);
                } else if (isMfaChallengeRequiredAuthError(e)) {
                    setMfaChallenged(true);
                } else {
                    actions.setStatus({
                        variant: "danger",
                        message: isAuthError(e) ? (e.message ?? "Error signing in") : "Unknown error",
                    } as Status);
                }
            })
            .finally(() => actions.setSubmitting(false));
    };

    if (isSignedIn) {
        return (
            <Navigate
                to={isLocationState(location.state) ? location.state.from : dashboardRoute.build({})}
                replace={true}
            />
        );
    }

    return (
        <CentredScreen>
            <img src={logo} alt="iSTORM®" className="img-fluid mb-3" />
            <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    setStatus,
                    values,
                    touched,
                    errors,
                    submitForm,
                    isSubmitting,
                    status,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <StatusAlert status={status as unknown} />
                        {mfaMethods === undefined ? (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.email && !!errors.email}
                                        autoComplete="off"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <FormControlPassword
                                        name="password"
                                        placeholder="Password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.password && !!errors.password}
                                        autoComplete="off"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </Form.Group>
                            </>
                        ) : null}
                        {mfaMethods !== undefined ? (
                            <Form.Group className="mb-3">
                                <MfaChallengeInput
                                    name="mfaChallenge"
                                    value={values.mfaChallenge ?? ""}
                                    remember={values.mfaRemember}
                                    supportedMethods={mfaMethods}
                                    selectedMethod={values.mfaMethod}
                                    challenged={mfaChallenged}
                                    onSelectedMethodChange={(value) => {
                                        void setFieldValue("mfaMethod", value);
                                        void submitForm();
                                        void setFieldTouched("mfaChallenge", false);
                                    }}
                                    onChange={(value) => setFieldValue("mfaChallenge", value)}
                                    onRememberChange={(value) => setFieldValue("mfaRemember", value)}
                                    onBlur={() => setFieldTouched("mfaChallenge")}
                                    isInvalid={touched.mfaChallenge && !!errors.mfaChallenge}
                                />
                                <Form.Control.Feedback type="invalid">{errors.mfaChallenge}</Form.Control.Feedback>

                                <BlockButtonGroup>
                                    <Button
                                        size="sm"
                                        variant="link"
                                        onClick={async () => {
                                            setMfaMethods(undefined);
                                            setMfaChallenged(false);
                                            setStatus(undefined);
                                            await setFieldValue("mfaMethod", undefined);
                                            await setFieldValue("mfaChallenge", undefined);
                                            await setFieldValue("mfaRemember", true);
                                            await setFieldTouched("mfaMethod", false);
                                            await setFieldTouched("mfaChallenge", false);
                                            await setFieldTouched("mfaRemember", false);
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </BlockButtonGroup>
                            </Form.Group>
                        ) : null}

                        <BlockButtonGroup>
                            <Button type="submit" disabled={isSubmitting}>
                                Sign in
                            </Button>
                            <ButtonLink variant="secondary" to={registerRoute.build({})}>
                                Register
                            </ButtonLink>
                            <ButtonLink variant="link" size="sm" to={resetPasswordRequestRoute.build({})}>
                                Forgot your password?
                            </ButtonLink>
                        </BlockButtonGroup>
                    </Form>
                )}
            </Formik>
        </CentredScreen>
    );
};
