import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteTestingTypeRoute, testingTypesRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useDeleteTestingTypeMutation, useTestingTypeQuery } from "app/api/graph/types";
import { testingTypeGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";
import { formatISODateTime } from "app/util/format";

export const DeleteTestingTypeScreen: FC = () => {
    const { testingTypeId } = useRouteParams(deleteTestingTypeRoute);
    const { data, loading, error } = useTestingTypeQuery({
        variables: { testingTypeId: testingTypeGraphId(testingTypeId) },
    });
    const [deleteTestingType, { data: deleteData, loading: deleting, error: deleteError }] =
        useDeleteTestingTypeMutation({
            variables: { input: { id: testingTypeGraphId(testingTypeId) } },
        });
    const testingType = data?.testingType;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!testingType) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteTestingType) {
        return <Navigate to={testingTypesRoute.build({})} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteTestingTypeRoute} />
                <h1>Delete testing type</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this testing type?</p>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{testingType.name ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created by</th>
                            <td>{testingType.createdByFullName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created at</th>
                            <td>{formatISODateTime(testingType.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                <Alert variant="danger">
                    <p>
                        This will <strong>permanently</strong> delete the testing type - which cannot be reversed.
                    </p>
                </Alert>
                <ConfirmDelete onConfirm={() => deleteTestingType()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
