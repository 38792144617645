import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaEdit, FaTrash } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useRouteParams } from "app/route/route";
import {
    deleteReportVersionVulnerabilityActionRoute,
    editReportVersionVulnerabilityActionRoute,
    reportVersionVulnerabilityActionRoute,
} from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useReportVulnerabilityActionQuery } from "app/api/graph/types";
import { reportVulnerabilityActionGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { formatISODate } from "app/util/format";
import { HtmlContent } from "app/component/util/HtmlContent";
import { ActionFiles } from "app/component/report-vulnerability-action/component/ActionFiles";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { useAuth } from "app/api/AuthContext";
import { deleteVulnerabilityActionRole, updateVulnerabilityActionRole } from "app/api/graph/roles";
import { ButtonLink } from "app/component/button/ButtonLink";

export const ReportVersionVulnerabilityActionScreen: FC = () => {
    const { roles } = useAuth();
    const { reportId, versionId, vulnerabilityId, actionId } = useRouteParams(reportVersionVulnerabilityActionRoute);
    const { data, loading, error } = useReportVulnerabilityActionQuery({
        variables: { actionId: reportVulnerabilityActionGraphId(actionId) },
    });
    const action = data?.reportVulnerabilityAction;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!action) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionVulnerabilityActionRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>{formatISODate(action.dateActioned)}</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        <ButtonGroup>
                            {roles?.includes(updateVulnerabilityActionRole) ? (
                                <ButtonLink
                                    variant="warning"
                                    to={editReportVersionVulnerabilityActionRoute.build({
                                        reportId,
                                        versionId,
                                        vulnerabilityId,
                                        actionId: action._id,
                                    })}
                                >
                                    <FaEdit /> Edit
                                </ButtonLink>
                            ) : null}
                            {roles?.includes(deleteVulnerabilityActionRole) ? (
                                <ButtonLink
                                    variant="danger"
                                    to={deleteReportVersionVulnerabilityActionRoute.build({
                                        reportId,
                                        versionId,
                                        vulnerabilityId,
                                        actionId: action._id,
                                    })}
                                >
                                    <FaTrash /> Delete
                                </ButtonLink>
                            ) : null}
                        </ButtonGroup>
                    </ToolbarButtons>
                </Toolbar>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>Client reference</th>
                            <td>{action.clientReference ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Actioned by</th>
                            <td>{action.createdByFullName ?? "-"}</td>
                        </tr>
                    </tbody>
                </Table>
                <HtmlContent html={action.description} />
                {(action.files?.edges?.length ?? 0 > 0) ? <ActionFiles action={action} versionId={versionId} /> : null}
            </Container>
        </NavigationScreen>
    );
};
