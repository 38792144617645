import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteCompanyRoute, companiesRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useDeleteCompanyMutation, useCompanyQuery } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";
import { formatISODateTime } from "app/util/format";

export const DeleteCompanyScreen: FC = () => {
    const { companyId } = useRouteParams(deleteCompanyRoute);
    const { data, loading, error } = useCompanyQuery({
        variables: { companyId: companyGraphId(companyId) },
    });
    const [deleteCompany, { data: deleteData, loading: deleting, error: deleteError }] = useDeleteCompanyMutation({
        variables: { input: { id: companyGraphId(companyId) } },
    });
    const company = data?.company;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!company) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteCompany) {
        return <Navigate to={companiesRoute.build({})} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteCompanyRoute} />
                <h1>Delete company</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this company?</p>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{company.name ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created by</th>
                            <td>{company.createdByFullName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created at</th>
                            <td>{formatISODateTime(company.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                <Alert variant="danger">
                    <p>
                        This will <strong>permanently</strong> delete the company - which cannot be reversed.
                    </p>
                    Note: this will delete the company and some related metadata (e.g. company invites). Users who have
                    joined this company will not be deleted and must be deleted separately. Reports belonging to the
                    company will not be deleted and must be deleted separately - the reports will no longer be
                    associated with a company.
                </Alert>
                <ConfirmDelete onConfirm={() => deleteCompany()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
